import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { SECTIONS } from "../Navbar/Navbar";
import { useTranslation } from "react-i18next";

export function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="dredyba-footer">
      <Container maxWidth="md" sx={{ p: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item textAlign="center" xs={12}>
            <Typography>
              {SECTIONS.map((page) => (
                <Button
                  key={page}
                  sx={{ color: "white" }}
                  onClick={() =>
                    document.getElementById(page)?.scrollIntoView()
                  }
                >
                  {t(`sections.${page}`)}
                </Button>
              ))}
            </Typography>

            <IconButton
              href="https://www.instagram.com/dredy.ba/"
              target="_blank"
              style={{ color: "#ffffff" }}
            >
              <InstagramIcon />
            </IconButton>

            <IconButton
              href="https://www.facebook.com/dredyba/"
              target="_blank"
              style={{ color: "#ffffff" }}
            >
              <FacebookIcon />
            </IconButton>

            <Typography>DREDY BA</Typography>
            <Typography>2023</Typography>
            <Typography>
              <span>Created with love by </span>
              <Link
                href="https://www.linkedin.com/in/maros-krajcovic"
                target="_blank"
              >
                Krajčo
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
